<template>
  <div id="merchant">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addVehicle"
              type="primary"
              @click="addBtn"
              v-if="
                buttonList.includes('parkingLotList-detail-merchantTab-addBtn')
              "
              ><i class="iconfont icon-jiahao"></i> 新建商户</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="shopName" label="商户名称" align="center">
        </el-table-column>
        <el-table-column prop="contactName" label="联系人" align="center">
        </el-table-column>
        <el-table-column prop="contactPhone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="shopAddress" label="位置" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="goToDetail(scope.row.userId)"
              v-if="
                buttonList.includes('parkingLotList-detail-merchantTab-detail')
              "
              >查看详情</el-button
            >
            <el-button
              type="text"
              @click="del(scope.row.userId)"
              v-if="
                buttonList.includes('parkingLotList-detail-merchantTab-delete')
              "
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <add-merchant-dialog ref="addMerchantDialog" :getList="getList" />
  </div>
</template>
<script>
export default {
  components: {
    addMerchantDialog: () => import("./addMerchantDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    del(userId) {
      this.$confirm("此操作将永久删除该商户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(`/parking/shop/${userId}/delete`);
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    goToDetail(userId) {
      this.$router.push({
        name: "merchantDetail",
        params: { id: userId },
        query: {
          parkingLotId: this.$route.params.id,
        },
      });
    },
    addBtn() {
      this.$refs.addMerchantDialog.show = true;
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/parking/shop/list", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
          parkingLotId: this.$route.params.id,
        });
        if (res.code === 0) {
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#merchant {
  .table-container {
    .table-filtrate {
      ul {
        .taigang {
          font-size: 12px;
          color: hsla(26, 100%, 64%, 0.96);
          span {
            color: hsla(217, 48%, 15%, 0.96);
            margin-right: 4px;
          }
        }
        li {
          .addVehicle {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
